import React, { ReactNode } from 'react';
import { Animated } from 'react-animated-css';
import { useLocation } from 'react-router-dom';
import { useGlobal } from 'context/global/GlobalContext';
import useElementSize from 'hooks/useElementSize';
import Dragbar from 'components/atomic/Dragbar';

type ModuleWrapperProps = {
  children?: any;
  transparent?: boolean;
  hideAnimation?: boolean;
  drawerTitle?: string | ReactNode;
};

const ModuleWrapper: React.FC<ModuleWrapperProps> = ({
  children,
  drawerTitle,
  transparent,
  hideAnimation,
}) => {
  const { slug, desktopView, openAutoDeployModal } = useGlobal();
  const [headerRef, { height }] = useElementSize();
  const location = useLocation();

  return (
    <div
      className={`relative h-full w-full max-w-full flex flex-col items-start justify-start overflow-x-hidden ${
        desktopView ? 'pt-4' : ''
      }`}
    >
      {location.pathname.includes(`/c/${slug}/form`) || !desktopView ? (
        <div
          ref={headerRef}
          className={`fixed top-0 left-0 w-full h-16 min-h-16 flex items-center rounded-[26px] 
            ${transparent ? '-z-10 bg-transparent' : 'z-10 bg-white'}
          `}
        >
          <div
            className={`absolute top-2 left-0 right-0 mx-auto ${
              desktopView && 'hidden'
            }`}
          >
            <Dragbar />
          </div>
          {typeof drawerTitle === 'object' ? (
            drawerTitle
          ) : (
            <h1
              className={`text-base text-left font-semibold leading-5 w-[calc(100%-6rem)] mr-2 ml-6 ${
                desktopView ? 'mt-0' : 'mt-2'
              }`}
            >
              {drawerTitle}
            </h1>
          )}
        </div>
      ) : null}
      <Animated
        isVisible={true}
        animationIn='slideInRight'
        animationOut='slideOutLeft'
        animationInDuration={hideAnimation ? 0 : 200}
        animationOutDuration={hideAnimation ? 0 : 200}
        style={{
          paddingTop:
            desktopView &&
            openAutoDeployModal &&
            location.pathname.includes(`/c/${slug}/form/`)
              ? '0px'
              : `${height - 12}px`,
          transitionDuration: '150ms',
          paddingBottom: `28px`,
          overflowX: 'hidden',
          height: '100%',
          width: '100%',
        }}
      >
        {children}
      </Animated>
    </div>
  );
};

export default ModuleWrapper;
