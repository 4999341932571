import React, { useEffect } from 'react';
import { Checkbox } from '@material-ui/core';
import { useFormik, FormikProps } from 'formik';
import { Scrollbar } from 'react-scrollbars-custom';
import { FormDetailModel, FormDetailOptions } from 'types/FormTypes';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import FormImage from './FormImage';

type FormCheckBoxProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: string[]) => void;
};

const FormCheckBox: React.FC<FormCheckBoxProps> = ({
  name,
  formRef,
  formData,
  updateFormField,
}) => {
  const { brandTheme } = useThemeContext();
  const formikCheckBox = useFormik({
    initialValues: { checked: [] },
    onSubmit: () => {},
  });
  const { checked }: { checked: string[] } = formikCheckBox.values;

  useEffect(() => {
    if (formRef && formData.isRequired) formRef.validateField(name);
    if (formRef && formRef.values[name]?.length && !checked?.length) {
      formikCheckBox.setFieldValue('checked', formRef.values[name], true);
      formikCheckBox.validateField(name);
    }
  }, [formRef?.values[name]]);

  const handleChecked = (value: FormDetailOptions) => {
    if (formRef?.values[name]?.length && !checked?.length)
      return !!formRef.values[name].find((x: string) => x === value.text);
    else if (checked?.length)
      return !!checked.find((x: string) => x === value.text);
    else return value.isDefault;
  };

  return formRef && formData ? (
    <div className='w-full h-full flex flex-col justify-start px-3 overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5
        className={`text-left text-lg leading-formTitle font-semibold text-black ${
          !formData.subText ? 'mb-3' : 'mb-0'
        }`}
      >
        {formData.text}
      </h5>
      {formData.subText && (
        <p className='mt-1.5 mb-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
          {formData.subText}
        </p>
      )}
      <Scrollbar
        translateContentSizeYToHolder
        style={{ width: 'auto', marginTop: '4px' }}
      >
        {formData.options.map((value, index) => (
          <div
            key={`${value}-${index}`}
            className='w-full flex items-start justify-start'
          >
            <Checkbox
              name='checked'
              id={value.text}
              value={value.text}
              className='w-5 h-5'
              style={{ color: handleChecked(value) ? brandTheme : '#414149' }}
              checked={handleChecked(value)}
              onChange={async (event) => {
                formikCheckBox.handleChange(event);
                let copy: string[] = [...checked];
                if (!~copy.indexOf(event.target.value))
                  copy = [event.target.value, ...checked];
                else
                  copy = copy.filter((value) => value !== event.target.value);
                await updateFormField(name, copy);
                formRef.validateField(name);
              }}
            />
            <label
              className='ml-3 mb-3 text-sm font-semibold leading-5 text-dark'
              htmlFor={value.text}
            >
              {value.text}
            </label>
          </div>
        ))}
      </Scrollbar>
    </div>
  ) : (
    <LoadingIndicator />
  );
};

export default FormCheckBox;
