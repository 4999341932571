import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ModuleInfoType } from '../../types/ProductDetailsType';
import { PossibleExperienceType } from 'types/ExperienceTypes';
import { EventPayload } from '../../hooks/useLogEvent';
import { getLocalStorage } from 'utils/storage';
import { GlobalContext } from './GlobalContext';
import { ReceiptType } from 'types/ReceiptType';
import ScreenType from 'types/ScreenType';
import useUser from '../../hooks/useUser';
import useWindowSize from 'hooks/useWindowSize';
import useLogEvent from '../../hooks/useLogEvent';
import useCollection from '../../hooks/useCollection';
import useProductDetails from '../../hooks/useProductDetails';
import useTermsAndPrivacyDetails from 'hooks/useTermsAndPrivacy';
import useSession from 'hooks/useSession';
import useDeviceId from 'hooks/useDeviceId';
import useGameRules from 'hooks/useGameRules';

export enum MAGIC_ACTION {
  OPEN_MODULE = 'OPEN_MODULE',
  REDIRECT = 'REDIRECT',
  NONE = 'NONE',
}

export const GlobalProvider: React.FC = ({ children }) => {
  const [allowClose, setAllowClose] = useState<boolean>(true);
  const [emailConsent, toggleEmailConsent] = useState<boolean>(false);
  const [privacyConsent, togglePrivacyConsent] = useState<boolean>(false);
  const [smsConsent, toggleSmsConsent] = useState<boolean>(false);
  const [globalLoading, setGlobalLoading] = useState<boolean>(false);
  const [screen, setScreen] = useState<ScreenType>(ScreenType.NONE);
  const [landOnMoreScreen, setLandOnMoreScreen] = useState<boolean>(false);
  const [multipleRegistrationStarted, setMultipleRegistrationStarted] =
    useState<boolean>(false);

  const screenTransitionRefId = useRef<NodeJS.Timeout | null>(null);

  const [registrationScreen, setRegistrationScreen] = useState<ScreenType>(
    ScreenType.NONE
  );
  const [openAutoDeployModal, _setOpenAutoDeployModal] =
    useState<boolean>(false);
  const [userClosedAutoDeployDrawer, setUserClosedAutoDeployDrawer] =
    useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [signInRedirect, setSignInRedirect] = useState<string>(
    getLocalStorage().getItem('signInRedirect') || ''
  );
  const [desktopView, setDesktopView] = useState<boolean>(false);
  const [registeringProduct, setRegisteringProduct] = useState<boolean>(false);
  const [slug, setSlug] = useState<string | null>(null);
  const [appZoom, setAppZoom] = useState(1);
  const [previewEvent, setPreviewEvent] = useState<MessageEvent | null>(null);
  const [previewAuthenticated, setPreviewAuthenticated] = useState(false);
  const [magicAction, setMagicAction] = useState<MAGIC_ACTION>(
    MAGIC_ACTION.REDIRECT
  );
  const [agegateDisplay, toggleAgegateDisplay] = useState<boolean>(false);
  const [contentGateDisplay, toggleContentGateDisplay] =
    useState<boolean>(false);
  const [magicPayload, setMagicPayload] = useState<any>({});
  const [autoDeployTriggered, setAutoDeployTriggered] =
    useState<boolean>(false);
  const [formTitle, setFormTitle] = useState<string>('');
  const [productModule, setProductModule] = useState<string>('');
  const [redirectResolved, setRedirectResolved] = useState<boolean>(false);
  const [formRegistration, setFormRegistration] =
    useState<ModuleInfoType | null>(null);
  const [receiptData, setReceiptData] = useState<ReceiptType | null>(null);
  // this is used for auto deploy to let me know if it's a new user and show personal details
  const [autoDeployIsNewUser, setAutoDeployIsNewUser] =
    useState<boolean>(false);
  // we are getting an extra rerender on registration and causing isnewuser to be null
  // moving to global context for now to persist state.
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  // set is video playing.
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [registrationId, setRegistrationId] = useState<string | null>(null);
  const [newRegistrationId, setNewRegistrationId] = useState<string | null>(
    null
  );

  const sessionId = useSession();
  const deviceId = useDeviceId();

  const {
    user,
    personalDetails,
    getPersonalDetails,
    setUser,
    authFetched,
    token,
    setToken,
    setpersonalDetails,
    getUserAsync,
  } = useUser();

  const { width } = useWindowSize();
  const [productDetails, reFetchProduct, productLoading, setProductDetails] =
    useProductDetails(slug, token, previewEvent, authFetched, registrationId);

  const [
    sweepstakesGameRules,
    setSweepstakesGameRules,
    gameRulesLoading,
    setGameRulesLoading,
  ] = useGameRules(productDetails, productLoading, slug, token);

  const [
    termsAndPrivacyDetails,
    setTermsAndPrivacyDetails,
    termsLoading,
    setTermsLoading,
  ] = useTermsAndPrivacyDetails(productDetails, productLoading, slug, token);

  const [collectionDetails, getCollection] = useCollection(token);
  const { enablePurchaseDetails, purchaseTemplate, completeProfile } =
    productDetails?.registration || {};

  const getCurrentRegistrationId = useCallback(() => {
    // get the newest generated id
    if (newRegistrationId) {
      return newRegistrationId;
    }
    // if no local registrationId is present use the one from the product
    if (registrationId) {
      return registrationId;
    }

    // fallback to first registration id for the product
    return (
      personalDetails?.profile.productCollection?.find(
        (product) => product.tagId === slug && !product.incomplete
      )?.registrationId || null
    );
  }, [personalDetails, newRegistrationId, registrationId]);

  useEffect(() => {
    if (registrationId) {
      // reset new registration id
      setNewRegistrationId(null);
    }
  }, [registrationId]);

  useEffect(() => {
    if (width) {
      if (width < 1080) setDesktopView(false);
      else if (
        width >= 1080 &&
        productDetails?.product.enableDesktopView &&
        productDetails?.product?.qrCodeDestination !==
          PossibleExperienceType.SMS_OPT_IN
      )
        setDesktopView(true);
      else setDesktopView(false);
    }
  }, [
    productDetails?.product?.qrCodeDestination,
    productDetails?.product.enableDesktopView,
    width,
  ]);

  useEffect(() => {
    if (slug) setAutoDeployTriggered(false);
  }, [slug]);

  useEffect(() => {
    if (productDetails && typeof window !== 'undefined') {
      getLocalStorage().setItem(
        'brijProductDetails',
        JSON.stringify(productDetails)
      );
    }
  }, [productDetails]);

  useEffect(() => {
    if (window) {
      window.addEventListener(
        'message',
        (event: MessageEvent) => {
          setPreviewEvent({ ...event.data });
          if (event && event.data && event.data.type === 'enablePreview') {
            setUser(null);
            setAppZoom(event.data.zoom);
            let style = document.getElementById('setZoomStyleForDropdowns');
            if (!style) {
              style = document.createElement('style');
            }
            style.setAttribute('id', 'setZoomStyleForDropdowns');
            style.innerText =
              '.MuiPopover-root{zoom: ' +
              event.data.zoom +
              '; } .MuiPaper-root { left: 7% !important}';
            window.document.body.appendChild(style);
          }
          if (event && event.data && event.data.type === 'setAuthState') {
            setPreviewAuthenticated(event.data.data);
          }
        },
        false
      );
    }
  }, [setUser]);

  useEffect(() => {
    getLocalStorage().setItem('signInRedirect', signInRedirect);
    // upon unmount clear storage
    return () => {
      getLocalStorage().setItem('signInRedirect', '');
    };
  }, [signInRedirect]);

  const _logEvent = useLogEvent(slug);

  const setOpenAutoDeployModal = (value: boolean) => {
    _setOpenAutoDeployModal(value);
    if (!value) {
      setUserClosedAutoDeployDrawer(true);
    }
  };

  const logEvent = useCallback(
    (
      payload: Pick<
        EventPayload,
        'event' | 'eventType' | 'moduleType' | 'moduleId' | 'data'
      >
    ) => {
      if (!productDetails) {
        return;
      }

      return _logEvent({
        ...payload,
        user: user?.uid,
        product: productDetails?.product.id,
        tag: productDetails?.tag.slug,
        brand: productDetails?.brand.id,
      });
    },
    [
      _logEvent,
      productDetails?.product.id,
      productDetails?.tag.slug,
      productDetails?.brand.id,
      user?.uid,
    ]
  );

  const _setScreen = (
    screen: ScreenType,
    multipleRegistrationStarted: boolean = false
  ) => {
    console.trace(screen, 'currentScreen');

    if (screenTransitionRefId.current) {
      clearTimeout(screenTransitionRefId.current);
    }

    if (screen === ScreenType.CURRENT_MODULE && multipleRegistrationStarted) {
      setScreen(ScreenType.MULTIPLE_REGISTRATION);
      return;
    }

    setScreen(screen);

    if (screen === ScreenType.CURRENT_MODULE) {
      return;
    }

    setRegistrationScreen(screen);
  };

  const setScreenTransition = (
    screen: ScreenType,
    multipleRegistrationStarted: boolean
  ) => {
    if (screenTransitionRefId.current) {
      clearTimeout(screenTransitionRefId.current);
    }

    screenTransitionRefId.current = setTimeout(() => {
      _setScreen(screen, multipleRegistrationStarted);
    }, 1000);
  };

  // register the product and send to appropriate screen
  const registerProduct = useCallback(() => {
    setRegisteringProduct(true);

    if (enablePurchaseDetails && purchaseTemplate?.name) {
      setScreenTransition(
        ScreenType.CURRENT_MODULE,
        multipleRegistrationStarted
      );
      return;
    }

    if (!completeProfile) {
      setScreenTransition(
        ScreenType.CURRENT_MODULE,
        multipleRegistrationStarted
      );
      return;
    }

    setScreenTransition(
      ScreenType.COMPLETE_PROFILE,
      multipleRegistrationStarted
    );
  }, [
    enablePurchaseDetails,
    purchaseTemplate?.name,
    completeProfile,
    multipleRegistrationStarted,
  ]);

  return (
    <GlobalContext.Provider
      value={{
        previewEvent,
        setPreviewEvent,
        appZoom,
        setAppZoom,
        previewAuthenticated,
        setPreviewAuthenticated,
        termsAndPrivacyDetails,
        setTermsAndPrivacyDetails,
        termsLoading,
        setTermsLoading,
        sweepstakesGameRules,
        setSweepstakesGameRules,
        gameRulesLoading,
        setGameRulesLoading,
        isMenuOpen,
        setIsMenuOpen,
        signInRedirect,
        setSignInRedirect,
        user,
        productDetails,
        loading: productLoading,
        slug,
        setSlug,
        setUser,
        personalDetails,
        setpersonalDetails,
        collectionDetails,
        getCollection,
        authFetched,
        getPersonalDetails,
        token,
        logEvent,
        magicAction,
        setMagicAction,
        magicPayload,
        setMagicPayload,
        agegateDisplay,
        toggleAgegateDisplay,
        contentGateDisplay,
        toggleContentGateDisplay,
        autoDeployTriggered,
        setAutoDeployTriggered,
        productModule,
        setProductModule,
        reFetchProduct,
        setProductDetails,
        setRegisteringProduct,
        registeringProduct,
        redirectResolved,
        setRedirectResolved,
        setToken,
        formRegistration,
        setFormRegistration,
        autoDeployIsNewUser,
        setAutoDeployIsNewUser,
        isNewUser,
        setIsNewUser,
        isVideoPlaying,
        setIsVideoPlaying,
        receiptData,
        setReceiptData,
        openAutoDeployModal,
        setOpenAutoDeployModal,
        userClosedAutoDeployDrawer,
        setUserClosedAutoDeployDrawer,
        globalLoading,
        setGlobalLoading,
        screen,
        setScreen: _setScreen,
        registrationScreen,
        emailConsent,
        toggleEmailConsent,
        privacyConsent,
        togglePrivacyConsent,
        smsConsent,
        toggleSmsConsent,
        registerProduct,
        formTitle,
        setFormTitle,
        getUserAsync,
        setAllowClose,
        allowClose,
        desktopView,
        setDesktopView,
        deviceId,
        sessionId,
        registrationId,
        setRegistrationId,
        newRegistrationId,
        setNewRegistrationId,
        multipleRegistrationStarted,
        setMultipleRegistrationStarted,
        getCurrentRegistrationId,
        landOnMoreScreen,
        setLandOnMoreScreen,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
