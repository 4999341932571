import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProps } from 'formik';
import { FormDetailModel } from 'types/FormTypes';
import CustomSelectInput from 'components/atomic/CustomSelectInput';
import FormImage from './FormImage';

type FormDropDownProps = {
  formRef: FormikProps<any> | null;
  formData: FormDetailModel;
  name: string;
  updateFormField: (name: string, value: string) => void;
};

const FormDropDown: React.FC<FormDropDownProps> = ({
  formRef,
  name,
  formData,
  updateFormField,
}) => {
  const formikDropDown = useFormik({
    initialValues: { dropDown: '' },
    onSubmit: () => {},
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer.dropDown',
  });

  useEffect(() => {
    if (formRef && !formRef.values[name] && formData.isRequired)
      formRef.validateField(name);
    if (formRef && formRef.values[name] && !formikDropDown.values.dropDown) {
      formikDropDown.setFieldValue('dropDown', formRef.values[name]);
      formRef.validateField(name);
    }
  }, [formRef?.values[name]]);

  return (
    <div className='w-full h-full flex flex-col justify-start px-3 overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5
        className={`text-left text-lg leading-formTitle font-semibold text-black ${
          !formData.subText ? 'mb-3' : 'mb-0'
        }`}
      >
        {formData.text}
      </h5>
      {formData.subText && (
        <p className='mt-1.5 mb-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
          {formData.subText}
        </p>
      )}
      <div className='w-full flex pb-40'>
        {formRef && (
          <CustomSelectInput
            type='text'
            name='drop-down'
            placeholder={formikDropDown.values.dropDown ? '' : t('selectText')}
            selected={{
              value: formRef.values[name],
              label: formRef.values[name],
            }}
            options={formData.options.map((item) => ({
              value: item.text,
              label: item.text,
            }))}
            onChange={async (option) => {
              await formikDropDown.setValues({ dropDown: option.value }, true);
              if (formikDropDown.isValid) {
                await updateFormField(name, option.value);
                formRef.validateForm();
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FormDropDown;
