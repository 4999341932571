import React, { useEffect, useState } from 'react';
import { FormDetailModel } from 'types/FormTypes';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import FormImage from './FormImage';
import CustomSelectInput from 'components/atomic/CustomSelectInput';

type OptionType = {
  value: string;
  label: string;
};

type FormNumberProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: any) => void;
};

const FormRetailChannel: React.FC<FormNumberProps> = ({
  name,
  formRef,
  formData,
  updateFormField,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer.textArea',
  });

  const { channels, includeOther } = (formData?.config as any) || {};

  const [purchasePlace, setPurchasePlace] = useState<OptionType>({
    value: '',
    label: '',
  });

  useEffect(() => {
    if (formRef && formRef.values[name]) {
      const { value, label } = formRef.values[name];
      setPurchasePlace({
        value,
        label,
      });
    }

    formRef?.validateForm();
  }, []);

  const updateRetailChannel = (value: OptionType) => {
    setPurchasePlace(value);

    const custom = value?.value === 'Other';
    const valueToSend = custom ? value?.label : value?.value;

    updateFormField(name, {
      custom,
      value: valueToSend,
      label: value?.label,
    });
  };

  return formRef ? (
    <div className='w-full h-full flex flex-col justify-start px-3 overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5
        className={`text-left text-lg leading-formTitle font-semibold text-black ${
          !formData.subText ? 'mb-3' : 'mb-0'
        }`}
      >
        {formData.text}
      </h5>
      {formData.subText && (
        <p className='mt-1.5 mb-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
          {formData.subText}
        </p>
      )}
      <div className='relative w-full flex'>
        <CustomSelectInput
          required
          type='text'
          key='retail-channel'
          name='retail-channel'
          placeholder={t('placeHolder')}
          options={channels}
          selected={purchasePlace}
          customValueAllowed={includeOther}
          customValueLabel={includeOther ? 'Other' : ''}
          onChange={(option: OptionType) => updateRetailChannel(option)}
        />
      </div>
    </div>
  ) : (
    <LoadingIndicator />
  );
};

export default FormRetailChannel;
