import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IFileFrom } from 'types/FileType';
import { useTranslation } from 'react-i18next';
import { CornerStyle } from 'context/ThemeContext/ThemeContext';
import { useThemeContext } from 'context/ThemeContext/ThemeContext';
import { ReactComponent as Upload } from 'assets/icons/svg/upload.svg';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import placeholder from 'assets/images/png/pdf-placeholder.png';
import videoPlaceholder from 'assets/icons/svg/video-icon.svg';
import ImagePreview from './ImagePreview';
import { getFileType } from 'utils/productUtils';

type FileUploadProps = {
  loading?: boolean;
  multiple?: boolean;
  uploadLimit?: number;
  validatePdf?: boolean;
  uploadButtonText?: string;
  selectedFiles: IFileFrom[] | undefined;
  changeFile(files: FileList | null): void;
  setSelectedFiles: (files: IFileFrom[]) => void;
};

const FileUpload: React.FC<FileUploadProps> = ({
  loading,
  changeFile,
  selectedFiles,
  setSelectedFiles,
  uploadLimit = 0,
  multiple = false,
  uploadButtonText,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const { inputStyle } = useThemeContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer.fileUpload',
  });

  const getCornerStyle = () => {
    switch (inputStyle) {
      case CornerStyle.FULL_ROUND:
        return 'rounded-[26px] rounded-[26px]';
      case CornerStyle.ROUNDED_CORNERS:
        return 'rounded-[10px] rounded-[10px]';
      case CornerStyle.SQUARE_CORNERS:
        return 'rounded-none';
      default:
        return 'rounded-[26px] rounded-[26px]';
    }
  };

  const removeFile = (e: any, index: number) => {
    e.stopPropagation();
    e.preventDefault();

    const files = selectedFiles?.filter((_, i) => i !== index);
    if (files?.length === 0 || files === undefined) setSelectedFiles([]);
    else setSelectedFiles(files);
  };

  const openImageUploader = () => inputRef?.current?.click();

  const validateFileSize = (files: FileList | null) => {
    if (!files) return false;
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 20 * 1024 * 1024) {
        return false;
      }
    }
    return true;
  };

  const onFileUpload = (e: any) => {
    if (!validateFileSize(e.target.files)) {
      toast.error(t('fileSizeError'));
      return;
    } else {
      if (uploadLimit > 0 && e.target.files.length > uploadLimit) {
        toast.error(t('fileLimitError'));
        return;
      } else if (selectedFiles?.length + e.target.files.length > uploadLimit) {
        toast.error(t('fileLimitError'));
        return;
      } else {
        changeFile(e.target.files);
        e.target.value = '';
      }
    }
  };

  const showUploadButton = () => {
    if (multiple) return true;
    if (!!selectedFiles?.length) return false;
    return true;
  };

  return (
    <div
      className={`relative w-full flex items-center justify-center flex-col border-solid border-lightBorder cursor-pointer transition duration-300 
        ${getCornerStyle()} 
        ${
          isFocused || !!selectedFiles?.length
            ? 'bg-transparent border'
            : 'bg-white border-2'
        } 
        ${!!selectedFiles?.length ? 'shadow-none' : 'h-button'}`}
    >
      <>
        <input
          type='file'
          name='file[]'
          id='file'
          className='hidden'
          accept='image/png, image/jpeg, image/jpg, .pdf, .heic, .mp4, .mkv, video/mp4, video/mkv'
          multiple={multiple ? true : false}
          onChange={onFileUpload}
          ref={inputRef}
        />
        {showUploadButton() && (
          <div
            onClick={openImageUploader}
            className='w-full flex items-center pt-1 px-2 justify-center cursor-pointer'
          >
            {loading ? (
              <LoadingIndicator size='50px' />
            ) : (
              <div className='w-full h-[50px] flex flex-row items-center justify-center gap-1'>
                <Upload
                  fill='#202029'
                  width={28}
                  height={28}
                  className='w-[28px] min-w-[28px] h-[28px] min-h-[28px] mb-0.5'
                />
                <span className='text-base leading-4 font-semibold text-dark'>
                  {uploadButtonText || t('uploadFileButton')}
                </span>
              </div>
            )}
          </div>
        )}
        <div
          className='flex flex-wrap w-full text-black text-[0.9rem] cursor-pointer bg-transparent'
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          {selectedFiles?.map((file, idx) => {
            let src = file.fileUrl;
            switch (getFileType(src)) {
              case 'pdf':
                src = placeholder;
                break;
              case 'video':
                src = videoPlaceholder;
                break;
              default:
                break;
            }
            const onRemove = (e: any) => {
              if (removeFile) return removeFile(e, idx);
              changeFile(null);
            };
            return (
              <ImagePreview
                key={idx}
                src={src || ''}
                onRemove={onRemove}
                showSuccessLayer={file.receiptVerificationStatus === 'valid'}
                showWarningLayer={file.receiptVerificationStatus === 'invalid'}
              />
            );
          })}
        </div>
      </>
    </div>
  );
};

export default FileUpload;
