import React, { useEffect } from 'react';
import { useFormik, FormikProps } from 'formik';
import { FormDetailModel } from 'types/FormTypes';
import { Scrollbar } from 'react-scrollbars-custom';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import RadioButtons from 'components/atomic/RadioButtons';
import FormImage from './FormImage';

type FormMultipleChoiceProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: string) => void;
};

const FormMultipleChoice: React.FC<FormMultipleChoiceProps> = ({
  name,
  formRef,
  formData,
  updateFormField,
}) => {
  const optionsArr = formData.options.map((value) => value.text);
  const formikMultipleChoice = useFormik({
    initialValues: { multipleChoice: '' },
    onSubmit: () => {},
  });

  useEffect(() => {
    if (formRef && !formRef.values[name] && formData.isRequired) {
      formRef.validateField(name);
    }
    if (formRef && formRef.values[name]) {
      formikMultipleChoice.setFieldValue(
        'multipleChoice',
        formRef.values[name]
      );
      formikMultipleChoice.validateForm();
      formRef.validateForm();
    }
  }, [formRef?.values[name]]);

  return formRef ? (
    <div className='w-full h-full flex flex-col justify-start px-3 overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5
        className={`text-left text-lg leading-formTitle font-semibold text-black ${
          !formData.subText ? 'mb-3' : 'mb-0'
        }`}
      >
        {formData.text}
      </h5>
      {formData.subText && (
        <p className='my-1.5 text-left text-xs font-semibold leading-formSubtitle text-muted'>
          {formData.subText}
        </p>
      )}
      <Scrollbar style={{ width: 'auto' }} translateContentSizeYToHolder>
        <RadioButtons
          name='multipleChoice'
          defaultValue={formikMultipleChoice.values.multipleChoice}
          options={optionsArr}
          onChange={async (value, e) => {
            await formikMultipleChoice.handleChange(e);
            if (formikMultipleChoice.isValid) {
              await updateFormField(name, value);
              formRef.validateForm();
            }
          }}
        />
      </Scrollbar>
    </div>
  ) : (
    <LoadingIndicator />
  );
};

export default FormMultipleChoice;
