import React from 'react';
import { theme } from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { ApprovalStatus } from './WarrantyDrawer';
import { useGlobal } from 'context/global/GlobalContext';
import { ReactComponent as Warning } from 'assets/icons/svg/warning.svg';
import { ReactComponent as Checkmark } from 'assets/icons/svg/checkmark.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/svg/pending.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import futureDateCalculator from 'utils/futureDateCalculator';
import ReceiptUpload from 'components/forms/ReceiptUpload';
import useRedirectLink from 'hooks/useRedirectLink';
import Button from 'components/atomic/Button';
import ScreenType from 'types/ScreenType';

type WarrantyInfoProps = {
  title: string;
  content?: string;
  issueDate: string;
  expiryDate: string;
  lifetime?: boolean;
  brandTheme?: string;
  registrationData?: any;
  approvalStatus: ApprovalStatus;
  showReceiptUploadOption?: boolean;
  mulberryContent: {
    policyTermsUrl: string;
    termLength: string;
    purchasedDate: string;
  } | null;
  warrantyClaim?: {
    enabled: boolean;
    cta: string;
    url: string;
  } | null;
};

const getTextClassName = (approvalStatus: ApprovalStatus): string => {
  switch (approvalStatus) {
    case ApprovalStatus.APPROVED:
      return 'text-brij';
    case ApprovalStatus.PENDING:
      return 'text-caution';
    case ApprovalStatus.DENIED:
      return 'text-warning';
    case ApprovalStatus.EXPIRED:
      return 'text-warning';
    default:
      return 'text-brij';
  }
};

const getClassName = (approvalStatus: ApprovalStatus): string => {
  switch (approvalStatus) {
    case ApprovalStatus.APPROVED:
      return 'bg-validWarranty';
    case ApprovalStatus.PENDING:
      return 'bg-pendingWarranty';
    case ApprovalStatus.DENIED:
      return 'bg-expiredWarranty';
    case ApprovalStatus.EXPIRED:
      return 'bg-expiredWarranty';
    default:
      return 'bg-validWarranty';
  }
};

const WarrantyInfo: React.FC<WarrantyInfoProps> = ({
  title,
  content,
  lifetime,
  issueDate,
  expiryDate,
  warrantyClaim,
  approvalStatus,
  mulberryContent,
  registrationData,
  showReceiptUploadOption,
}) => {
  const { enableMultiRegistration, multiRegistrationCTA } = registrationData;
  const { setScreen } = useGlobal();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.warrantyDrawer',
  });

  const redirectToExternalLink = useRedirectLink();

  const getIcon = (approvalStatus: ApprovalStatus) => {
    switch (approvalStatus) {
      case ApprovalStatus.APPROVED:
        return <Checkmark width='15px' height='15px' fill={theme.primary} />;
      case ApprovalStatus.PENDING:
        return <PendingIcon width='15px' height='15px' fill='#FE931E' />;
      case ApprovalStatus.DENIED:
        return <Warning width='15px' height='15px' fill='#FD6157' />;
      case ApprovalStatus.EXPIRED:
        return <Warning width='15px' height='15px' fill='#FD6157' />;
      default:
        return <Checkmark width='15px' height='15px' fill={theme.primary} />;
    }
  };

  return (
    <div className='px-3 rounded-lg border-2 border-solid border-gray-100 mt-3'>
      <div
        className={`w-full flex items-center justify-center my-3 p-3 rounded ${getClassName(
          approvalStatus
        )}`}
      >
        {getIcon(approvalStatus)}
        <p
          className={`px-2 w-max text-sm text-center font-semibold ${getTextClassName(
            approvalStatus
          )}`}
        >
          {title}
        </p>
      </div>
      <div>
        <p
          className={`${getTextClassName(
            approvalStatus
          )} font-semibold text-sm mt-3 mb-3 text-center`}
        >
          {content}
        </p>
      </div>
      {approvalStatus !== ApprovalStatus.DENIED && (
        <div className='w-full flex mb-3 gap-3'>
          {mulberryContent && mulberryContent?.termLength ? (
            <div className='w-full flex flex-col flex-shrink items-center justify-center p-3 gap-0.5 border border-solid border-lightBorder bg-minimalGray rounded'>
              <p className='text-sm text-center font-medium text-muted'>
                {t('termLength')}
              </p>
              <p className='font-semibold text-sm text-center text-dark'>
                {parseInt(mulberryContent.termLength) / 12} years
              </p>
            </div>
          ) : (
            <div className='w-full flex flex-col flex-shrink items-center justify-center p-3 gap-0.5 border border-solid border-lightBorder bg-minimalGray rounded'>
              <p className='text-sm text-center font-medium text-muted'>
                {t('issueDate')}
              </p>
              <p className='font-semibold text-sm text-center text-dark'>
                {issueDate}
              </p>
            </div>
          )}
          {mulberryContent &&
          mulberryContent?.purchasedDate &&
          mulberryContent?.termLength ? (
            <div className='w-full flex flex-col flex-shrink items-center justify-center p-3 gap-0.5 border border-solid border-lightBorder bg-minimalGray rounded'>
              <p className='text-sm text-center font-medium text-muted'>
                {t('expirationDate')}
              </p>
              <p className='font-semibold text-sm text-center text-dark'>
                {futureDateCalculator(
                  mulberryContent.purchasedDate,
                  mulberryContent.termLength
                )}
              </p>
            </div>
          ) : (
            <div className='w-full flex flex-col flex-shrink items-center justify-center p-3 gap-0.5 border border-solid border-lightBorder bg-minimalGray rounded'>
              <p className='text-sm text-center font-medium text-muted'>
                {t('expirationDate')}
              </p>
              <p className='font-semibold text-sm text-center text-dark'>
                {lifetime ? t('lifetimeLabel') : expiryDate}
              </p>
            </div>
          )}
        </div>
      )}
      {mulberryContent && mulberryContent?.policyTermsUrl && (
        <div
          className='flex items-center justify-center gap-1 mb-3 cursor-pointer'
          onClick={() =>
            redirectToExternalLink(mulberryContent.policyTermsUrl, '_blank')
          }
        >
          <p className='text-sm font-medium text-black underline'>
            {t('mulberryPolicyTerms')}
          </p>
          <ExternalLink fill='#000000' width={20} height={20} />
        </div>
      )}
      {approvalStatus === ApprovalStatus.DENIED && showReceiptUploadOption && (
        <div className='mb-3'>
          <ReceiptUpload />
        </div>
      )}
      {warrantyClaim &&
        warrantyClaim.url &&
        warrantyClaim.enabled &&
        approvalStatus !== ApprovalStatus.PENDING &&
        approvalStatus !== ApprovalStatus.DENIED && (
          <Button
            styles='!mb-3 !bg-white !text-brij !border-brij'
            variant='light'
            title={warrantyClaim?.cta}
            onClick={() => redirectToExternalLink(warrantyClaim.url, '_blank')}
          />
        )}
      {mulberryContent && (
        <p className='text-xxs text-gray text-center mb-2'>
          {t('mulberryHelpText.part1')}{' '}
          <text
            className='underline cursor-pointer'
            onClick={() =>
              redirectToExternalLink('https://www.getmulberry.com', '_blank')
            }
          >
            Mulberry Technology Inc.
          </text>{' '}
          {t('mulberryHelpText.part2')}
        </p>
      )}
      {enableMultiRegistration ? (
        <button
          type='button'
          onClick={() => setScreen(ScreenType.MULTIPLE_REGISTRATION)}
          className='not-draggable w-full flex items-center justify-center text-sm font-medium text-black text-center underline mb-3'
        >
          {multiRegistrationCTA
            ? multiRegistrationCTA
            : t('multipleRegistrationText')}
        </button>
      ) : null}
    </div>
  );
};

export default WarrantyInfo;
