import React from 'react';
import useElementSize from 'hooks/useElementSize';

type ProgressBarProps = {
  steps: number;
  currentStep: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  steps = 5,
  currentStep = 1,
}) => {
  const [wrapperRef, { width }] = useElementSize();

  return (
    <div
      ref={wrapperRef}
      className='z-50 absolute left-0 w-[calc(100%_+_2rem)] h-3 flex items-center gap-0.5 bg-white'
    >
      {Array(steps)
        .fill(null)
        .map((value, index) => {
          return (
            <div
              key={`step-${index + 1}-${value}`}
              className='relative h-[5px] rounded-[9px] bg-lightBorder overflow-hidden'
              style={{
                width:
                  index === steps - 1
                    ? `${width / steps}px`
                    : `${width / steps - 4}px`,
              }}
            >
              <div
                className={`h-full duration-300 bg-primary ${
                  index > currentStep - 1 ? 'w-0' : 'w-full'
                }`}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ProgressBar;
