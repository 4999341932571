import { getLocalStorage } from './storage';
import envVars from 'env-vars';

const API_URL =
  envVars.REACT_APP_STAND_ALONE_MODE === 'false'
    ? envVars.REACT_APP_API_URL
    : '';

export const removeFormLocalStorageValues = () => {
  const localStorage = getLocalStorage();
  const allKeys = localStorage.getAllKeys();

  allKeys
    .filter(
      (x: string) =>
        x.startsWith('brij-form-') && !x.endsWith('-return-module-id')
    )
    .filter(
      (x: string) => x.startsWith('brij-form-') && !x.endsWith('-complete')
    )
    .forEach((x: string) => localStorage.removeItem(x));

  localStorage.removeItem('brij-current-module-id');
};

export const removeRegistrationFormLocalStorageValues = (
  slug: string | null,
  formModuleId: string
) => {
  const localStorage = getLocalStorage();
  localStorage.removeItem(`brij-form-${slug}-${formModuleId}-complete`);
};

export const validateAgainstList = async (
  value: string,
  listId?: string,
  limitUse?: boolean,
  maxUses?: number,
  maxUsesErrorMessage?: string,
  customizeInvalidEntryMessage?: boolean,
  invalidEntryMessage?: string,
  isCaseSensitive?: boolean
): Promise<{ isValid: boolean; errorMessage?: string }> => {
  try {
    const response = await fetch(`${API_URL}/app_api/list/validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        value,
        listId,
        limitUse,
        maxUses,
        isCaseSensitive,
      }),
    });

    if (!response.ok) {
      return { isValid: false };
    }

    const data = await response.json();
    let errorMessage;
    if (!data.validate) {
      switch (data.type) {
        case 'MAX_USE':
          errorMessage = maxUsesErrorMessage || data.errorMessage;
          break;
        case 'INVALID_VALUE':
          errorMessage = customizeInvalidEntryMessage
            ? invalidEntryMessage
            : data.errorMessage;
          break;
        default:
          errorMessage = data.errorMessage;
      }
    }
    return {
      isValid: data.validate,
      errorMessage: errorMessage,
    };
  } catch (error) {
    return { isValid: false };
  }
};
