import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ToxicElementType,
  ToxicElementWithLimitType,
} from 'types/TestResultsTypes';
import {
  ToxicElementLimitType,
  ToxicElementResultsType,
} from 'types/ProductDetailsType';
import { ReactComponent as ExternalLink } from 'assets/icons/svg/external-link.svg';
import { ReactComponent as CautionIcon } from 'assets/icons/svg/toast-info.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/svg/expand.svg';
import { ReactComponent as Checkmark } from 'assets/icons/svg/checkmark.svg';
import dateDifferenceCounter from 'utils/dateDifferenceCounter';
import HtmlWrapper from 'components/wrappers/HtmlWrapper';
import useRedirectLink from 'hooks/useRedirectLink';
import Tooltip from 'components/atomic/Tooltip';
import Button from 'components/atomic/Button';
import dateFormat from 'dateformat';

type ToxicElementsTableProps = {
  title: string;
  startExpanded?: boolean;
  testingDate: Date | null;
  expirationDate: Date | null;
  toxicElements: ToxicElementType[];
  toxicElementsResults: ToxicElementResultsType;
  testResultsFile: {
    fileLink: string;
    linkText: string;
  } | null;
};

const ToxicElementsTable: React.FC<ToxicElementsTableProps> = ({
  title,
  testingDate,
  toxicElements,
  startExpanded,
  expirationDate,
  testResultsFile,
  toxicElementsResults,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [limitExceeded, setLimitExceeded] = useState<boolean>(false);
  const [toxicElementsWithLimits, setToxicElementsWithLimits] = useState<
    ToxicElementWithLimitType[]
  >([]);
  const redirectToExternalLink = useRedirectLink();
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.ab899Drawer',
  });

  const {
    tooltipConfig,
    hideNullValues,
    hideTestingDate,
    educationalText,
    showStandardLimits,
    hideExpirationDate,
    overrideBelowthreshold,
    overrideNonNumericResults,
    hidePostExpirationResults,
  } = toxicElementsResults;

  useEffect(() => {
    if (toxicElementTile.length > 0) {
      if (
        (showStandardLimits.enabled &&
          showStandardLimits?.standardLimits &&
          showStandardLimits?.standardLimits?.length > 0) ||
        (overrideBelowthreshold.enabled &&
          overrideBelowthreshold.thresholdValues &&
          overrideBelowthreshold.thresholdValues.length > 0)
      ) {
        const matchedData = matchToxicElementsWithLimits(
          toxicElements,
          showStandardLimits.standardLimits,
          overrideBelowthreshold.thresholdValues
        );
        setToxicElementsWithLimits(matchedData);
        matchedData?.forEach((toxicElement) => {
          if (
            toxicElement.limit !== null &&
            toxicElement.amount !== null &&
            !isNaN(parseFloat(toxicElement.limit)) &&
            !isNaN(parseFloat(toxicElement.amount)) &&
            parseFloat(toxicElement.amount) > parseFloat(toxicElement.limit)
          ) {
            setLimitExceeded(true);
          }
        });
      } else {
        setToxicElementsWithLimits(
          hideNullValues
            ? toxicElements
                .filter(
                  (toxicElement: ToxicElementType) =>
                    toxicElement.amount !== null && toxicElement.amount !== ''
                )
                .map((toxicElement: ToxicElementType) => {
                  return {
                    ...toxicElement,
                    limit: '',
                    threshold: '',
                  };
                })
            : toxicElements.map((toxicElement: ToxicElementType) => {
                return {
                  ...toxicElement,
                  limit: '',
                  threshold: '',
                };
              })
        );
      }
    }
    if (startExpanded) setExpanded(true);
  }, []);

  const matchToxicElementsWithLimits = (
    toxicElements: ToxicElementType[],
    standardLimits: ToxicElementLimitType[],
    thresholdValues: ToxicElementLimitType[]
  ) => {
    return toxicElements.map((toxicElement: ToxicElementType) => {
      const limit = standardLimits.find(
        (limitItem: ToxicElementType) => limitItem.value === toxicElement.value
      );
      const threshold = thresholdValues.find(
        (thresholdItem: ToxicElementType) =>
          thresholdItem.value === toxicElement.value
      );
      let toxicElementWithLimit: ToxicElementWithLimitType = {
        ...toxicElement,
        limit: limit && showStandardLimits.enabled ? limit.amount : '',
        threshold:
          threshold && overrideBelowthreshold.enabled ? threshold.amount : '',
      };
      return toxicElementWithLimit;
    });
  };

  const toxicElementTile = (toxicElement: ToxicElementWithLimitType) => {
    let triggerLimit: boolean = false;
    if (
      toxicElement.limit !== null &&
      toxicElement.amount !== null &&
      showStandardLimits.enableAlerts &&
      !isNaN(parseFloat(toxicElement.limit)) &&
      !isNaN(parseFloat(toxicElement.amount)) &&
      parseFloat(toxicElement.amount) >= parseFloat(toxicElement.limit)
    ) {
      triggerLimit = true;
    }

    return hideNullValues &&
      (toxicElement.amount === null || toxicElement.amount === '') ? null : (
      <div
        key={`${toxicElement._id}`}
        className={`
          relative w-max min-w-[calc(50%_-_6px)] flex flex-col items-center justify-center rounded-lg border border-solid 
          ${
            triggerLimit
              ? 'bg-caution border-transparent'
              : 'bg-white border-lightgray'
          }
        `}
      >
        <p
          className={`
            w-full font-semibold text-xs text-center whitespace-nowrap capitalize p-1.5 border border-solid border-t-0 border-l-0 border-r-0
            ${
              triggerLimit
                ? 'text-caution border-white'
                : 'text-black border-lightgray'
            }
          `}
        >
          {toxicElement.value}
        </p>
        <div className='relative w-full h-12 px-3 flex flex-col items-center justify-center'>
          {toxicElement.amount === null || toxicElement.amount === '' ? (
            <p className='text-xs font-medium whitespace-nowrap'>-</p>
          ) : (
            <div className='flex gap-1'>
              <p
                className={`text-xs font-medium whitespace-nowrap ${
                  triggerLimit ? 'text-caution' : 'text-extraDarkGray'
                }`}
              >
                {overrideBelowthreshold.enabled &&
                overrideBelowthreshold.overrideText &&
                !isNaN(parseFloat(toxicElement.amount)) &&
                !isNaN(parseFloat(toxicElement.threshold)) &&
                parseFloat(toxicElement.amount) <
                  parseFloat(toxicElement.threshold)
                  ? overrideBelowthreshold.overrideText
                  : `${
                      isNaN(parseFloat(toxicElement.amount)) &&
                      overrideNonNumericResults.enabled &&
                      overrideNonNumericResults.overrideText
                        ? overrideNonNumericResults.overrideText
                        : toxicElement.amount
                    } 
                    ${!isNaN(parseFloat(toxicElement.amount)) ? 'ppb' : ''}`}
              </p>
              {triggerLimit ? (
                <CautionIcon
                  width={12}
                  height={12}
                  fill='#FE931E'
                  className='min-w-[12px] mt-0.5'
                />
              ) : !overrideNonNumericResults.enabled &&
                !overrideNonNumericResults.overrideText ? (
                <Checkmark
                  width={12}
                  height={12}
                  fill='#00D7C8'
                  className='min-w-[12px] mt-0.5'
                />
              ) : null}
            </div>
          )}
          {showStandardLimits && toxicElement.limit && (
            <p
              className={`text-xxs ${
                triggerLimit ? 'text-caution' : 'text-extraDarkGray'
              }`}
            >
              {`${t('standardHeading')}: < ${toxicElement.limit} ppb`}
            </p>
          )}
        </div>
      </div>
    );
  };

  if (
    expirationDate &&
    hidePostExpirationResults &&
    dateDifferenceCounter(expirationDate) > 31
  )
    return null;

  return (
    <div
      key={title.toLowerCase().replace(/ /g, '')}
      className='relative w-full flex flex-col rounded-lg border border-solid border-lightgray overflow-hidden'
    >
      <button
        type='button'
        onClick={() => setExpanded(!expanded)}
        className='relative w-full flex flex-row items-center justify-between p-3 gap-3 bg-minimalGray'
      >
        <h5 className='text-sm font-semibold text-left text-wrap text-ellipsis whitespace-nowrap overflow-hidden'>
          {title}
        </h5>
        <ExpandIcon
          className={`min-w-[18px] min-h-[18px] transform duration-150 ${
            expanded ? 'rotate-180' : 'rotate-0'
          }`}
          fill='#000000'
          height={18}
          width={18}
        />
      </button>
      {expanded && (
        <>
          <div className='relative w-full flex flex-col p-3 gap-3'>
            {educationalText?.enabled && (
              <HtmlWrapper
                styles='!text-xxs !text-gray !text-justify'
                html={educationalText?.text}
              />
            )}
            {(!hideTestingDate || !hideExpirationDate) && (
              <div className='relative w-full flex flex-row items-center justify-center gap-2'>
                {!hideTestingDate && testingDate && (
                  <div className='relative flex-1 flex-col items-center justify-center text-center p-3 gap-0.5 rounded-lg overflow-hidden bg-minimalGray'>
                    <h6 className='pb-0.5 text-xs text-muted font-light leading-5'>
                      {t('testDateHeading')}
                    </h6>
                    <p className='font-semibold text-xs text-extraDarkGray'>
                      {dateFormat(testingDate, 'mmm dd, yyyy')}
                    </p>
                  </div>
                )}
                {!hideExpirationDate && expirationDate && (
                  <div className='relative flex-1 flex-col items-center justify-center text-center p-3 gap-0.5 rounded-lg overflow-hidden bg-minimalGray'>
                    <h6 className='pb-0.5 text-xs text-muted font-light leading-5'>
                      {t('expirationDateHeading')}
                    </h6>
                    <p className='font-semibold text-xs text-extraDarkGray'>
                      {dateFormat(expirationDate, 'mmm dd, yyyy')}
                    </p>
                  </div>
                )}
              </div>
            )}
            {limitExceeded &&
              showStandardLimits.enableAlerts.enabled &&
              showStandardLimits.enableAlerts.alertText && (
                <div className='flex flex-row px-2.5 py-2 gap-1.5 bg-caution'>
                  <CautionIcon
                    width={14}
                    height={14}
                    fill='#FE931E'
                    className='min-w-[14px] mt-0.5'
                  />
                  <p className='text-xxs font-medium text-caution'>
                    {showStandardLimits.enableAlerts.alertText}
                  </p>
                </div>
              )}
            <div className='relative w-full flex flex-row flex-wrap justify-center gap-3'>
              {toxicElementsWithLimits?.map(
                (toxicElement: ToxicElementWithLimitType) =>
                  toxicElementTile(toxicElement)
              )}
            </div>
            {tooltipConfig?.enabled && (
              <Tooltip
                label={tooltipConfig?.label}
                content={tooltipConfig?.text}
              />
            )}
            {testResultsFile && (
              <Button
                type='button'
                variant='link'
                styles='!h-[18px] mb-1 mt-1.5'
                textStyles='!text-black !text-xs !font-medium !underline !tracking-[0.1px]'
                title={testResultsFile?.linkText}
                icon={
                  <ExternalLink
                    width={12}
                    height={12}
                    fill='#000000'
                    className='ml-0.5 mt-px'
                  />
                }
                onClick={() =>
                  redirectToExternalLink(testResultsFile?.fileLink, '_blank')
                }
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ToxicElementsTable;
