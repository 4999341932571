import React, { useEffect, ChangeEvent } from 'react';
import { FormDetailModel } from 'types/FormTypes';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from 'components/atomic/LoadingIndicator';
import TextArea from 'components/atomic/TextArea';
import Input from 'components/atomic/Input';
import FormImage from './FormImage';
import * as Yup from 'yup';

type FormTextFieldProps = {
  formData: FormDetailModel;
  formRef: FormikProps<any> | null;
  name: string;
  updateFormField: (name: string, value: string) => void;
};

const FormTextField: React.FC<FormTextFieldProps> = ({
  name,
  formRef,
  formData,
  updateFormField,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'drawers.formDrawer.textArea',
  });
  const formikTextArea = useFormik({
    initialValues: { textArea: '' },
    validationSchema: Yup.object({
      textArea: Yup.string().max(601, 'Max characters 600'),
    }),
    onSubmit: () => {},
  });

  useEffect(() => {
    if (formRef && formRef.values[name] && !formikTextArea.values.textArea)
      formikTextArea.setFieldValue('textArea', formRef.values[name]);
    formRef?.validateForm();
  }, []);

  return formRef ? (
    <div className='w-full h-max flex flex-col justify-start px-3 overflow-auto'>
      {formData.image && (
        <FormImage image={formData.image} alt='form-question-image' />
      )}
      <h5
        className={`text-left text-lg leading-formTitle font-semibold text-black ${
          !formData.subText ? 'mb-3' : 'mb-0'
        }`}
      >
        {formData.text}
      </h5>
      {formData.subText && (
        <p className='mt-1.5 mb-3 text-left text-xs font-semibold leading-formSubtitle text-muted'>
          {formData.subText}
        </p>
      )}
      <div className='w-full flex flex-col justify-start'>
        {formData.isLongQuestion ? (
          <TextArea
            name='textArea'
            value={formikTextArea.values.textArea}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              formikTextArea.handleChange(event);
              updateFormField(name, event.target.value);
            }}
            placeholder={formikTextArea.values.textArea ? '' : t('placeHolder')}
          />
        ) : (
          <Input
            name='textArea'
            autoComplete='off'
            value={formikTextArea.values.textArea}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              formikTextArea.handleChange(event);
              updateFormField(name, event.target.value);
            }}
            placeholder={formikTextArea.values.textArea ? '' : t('placeHolder')}
          />
        )}
        {formikTextArea.touched &&
          formikTextArea.dirty &&
          formRef.errors[name] && (
            <span className='text-warning text-[0.8rem]'>
              {formRef.errors[name]}
            </span>
          )}
      </div>
    </div>
  ) : (
    <LoadingIndicator />
  );
};

export default FormTextField;
